import { Box, Typography } from '@mui/material';
import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactLocation from '../../images/Contact-image.jpg';
import EmailIcon from '@mui/icons-material/Email';

export default function Contact() {
  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          boxShadow: 5,
          overflow: 'hidden',
          width: '95%',
          maxWidth: '100%',
          height: '700px',
          position: 'relative',
        }}
      >
        <img
          src={ContactLocation}
          alt="Description"
          style={{ 
            width: '100%', 
            height: '100%',
            objectFit: 'cover',
          }}
        />
        {/* Blur Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 1,
          }}
        />
        {/* Title */}
        <Typography
          variant="h2"
          sx={{
            position: 'absolute',
            top: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'white',
            zIndex: 2,
            fontWeight: 'medium',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          }}
        >
          Contact Us
        </Typography>
        {/* Blur Boxes Container */}
        <Box
          sx={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              width: '45%',
              height: '250px',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              backdropFilter: 'blur(10px)',
              borderRadius: '10px',
              marginRight: '10%',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            <LocationOnIcon 
              fontSize="large" 
              sx={{
                color: '#2E2E2E',
                filter: 'drop-shadow(0px 4px 4px rgba(100, 100, 100, 0.5))',
              }} 
            />
            <Typography variant="h5" fontWeight="bold" sx={{marginTop:"20px", textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',}}>Visit Us</Typography>
            <Typography variant="h6" align="center" fontWeight="bold" sx={{marginTop:"20px"}}>
                450 Folsom Street Unit #1203, 
                <br/>San Francisco, CA 94105</Typography>
          </Box>
          <Box
            sx={{
              width: '45%',
              height: '250px',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              backdropFilter: 'blur(10px)',
              borderRadius: '10px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            <EmailIcon 
              fontSize="large" 
              sx={{
                color: '#2E2E2E',
                filter: 'drop-shadow(0px 4px 4px rgba(100, 100, 100, 0.5))',
              }} 
            />
            <Typography variant="h5" fontWeight="bold" sx={{marginTop:"20px", textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>Email Us</Typography>
            <Typography variant="h6" align="center" fontWeight="bold" sx={{marginTop:"20px"}}>
                team@futurestateuniversity.com
            </Typography>
          
            </Box>
        </Box>
        <Typography variant="h5" align="center" fontWeight="medium" sx={{ position: 'absolute',
            top: '70%',
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'white',
            zIndex: 2,
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
            }}>
         Join the future of professional networking, education, and career attainment today!
        </Typography>
      </Box>
    </Box>
  );
}
