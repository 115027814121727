import { Box, Button } from "@mui/material";

const clipPath = `polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0% 100%)`;

export const CustomButton = ({ onClick, children }) => (
  <Box
    sx={{
      bottom:"20px",
      right:"20px",
      display: 'inline-block',
      clipPath: clipPath,
      backgroundColor: '#075985', // This will be the border color
      padding: '2px', // This determines the border thickness
      
    }}
  >
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: '#52D5F2',
        color: 'black',
        padding: '8px 16px',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        clipPath: clipPath,
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.7)',
          color:'white'
        },
      }}
    >
      {children}
    </Button>
  </Box>
);