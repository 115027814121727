import { Box } from "@mui/material";
import { renderDegreeScale } from "./renderCircularAnimations";

export const DegreeScale = () => {

    return(
        <Box className="absolute w-64 h-64 rounded-full border-2 border-sky-blue-dark backdrop-blur-md z-1">
            <Box
              sx={{
                position: "absolute",
                width: "175%",
                height: "175%",
                animation: "spin 20s linear infinite reverse",
                "@keyframes spin": {
                  "0%": { transform: "rotate(0deg)" },
                  "100%": { transform: "rotate(-360deg)" },
                },
                left: "-95px",
                top: "-95px",
              }}
            >
              {renderDegreeScale()}
            </Box>
          </Box>
    );

}