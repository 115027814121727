import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#87CEEB', // Sky Blue
      light: '#B0E0E6',
      dark: '#4682B4',
    },
    secondary: {
      main: '#1C1C1C', // Dark shade of black
      light: '#2C2C2C',
      dark: '#0C0C0C',
    },
  },
  typography: {
    fontFamily: 'Raleway, sans-serif',
  },
});

export default theme;