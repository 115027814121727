import React from 'react';
import Footer from './homeComponents/Footer';
import Mission from './aboutComponents/Mission';

export default function AboutMission() {
  return (
    <>
    <Mission/>
    <Footer/>
    </>
  )
}
