import React, { useState, useEffect, memo } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import LoadingOverlay from "../loadingOverlay/loadingOverlay";

const MyUnityGame = memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [showUnity, setShowUnity] = useState(false);

  const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: "https://game.futurestateuniversity.com/FSUServerBuild/WebGL/Build/WebGL.loader.js",
    dataUrl: "https://game.futurestateuniversity.com/FSUServerBuild/WebGL/Build/WebGL.data.gz",
    frameworkUrl: "https://game.futurestateuniversity.com/FSUServerBuild/WebGL/Build/WebGL.framework.js.gz",
    codeUrl: "https://game.futurestateuniversity.com/FSUServerBuild/WebGL/Build/WebGL.wasm.gz",
  });

  useEffect(() => {
    const newProgress = loadingProgression * 100;
    if (newProgress !== progress) {
      setProgress(newProgress);
    }
    if (isLoaded && isLoading) {
      setIsLoading(false);
    }
  }, [isLoaded, loadingProgression, isLoading, progress]);

  const handleEnter = () => {
    if (isLoaded) {
      setShowUnity(true);
    }
  };

  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
      {!showUnity && (
        <LoadingOverlay
          onEnter={handleEnter}
          isLoading={isLoading}
          progress={progress}
        />
      )}
      <Unity
        unityProvider={unityProvider}
        style={{
          visibility: showUnity ? "visible" : "hidden",
          width: "100vw",
          height: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
});

export default MyUnityGame;