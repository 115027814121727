import React, { memo, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import image1 from '../../images/image1.png';
import image2 from '../../images/image2.png';
import image3 from '../../images/image3.png';
import image4 from '../../images/image4.png';

const carouselItems = [
  { image: image1, caption: 'Welcome to our 3D world' },
  { image: image2, caption: 'Explore amazing landscapes' },
  { image: image3, caption: 'Interact with unique objects' },
  { image: image4, caption: 'Discover new perspectives' }
];

const LoadingCarousel = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () => resolve(image);
        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(carouselItems.map(item => loadImage(item.image)))
      .then(() => setImagesLoaded(true))
      .catch(err => console.error("Failed to load images", err));
  }, []);

  if (!imagesLoaded) {
    return <Box>Loading images...</Box>;
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <Carousel
        autoPlay
        animation="slide"
        navButtonsAlwaysInvisible={true}
        interval={5000}
        indicators={false}
        index={currentIndex}
        onChange={(index) => setCurrentIndex(index)}
        sx={{
          width: '100%',
          height: '100%',
          boxShadow:"0 0 0 10px black"
        }}
      >
        {carouselItems.map((item, index) => (
          <Box
            key={index}
            sx={{ 
              height: '100%', 
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
              }
            }}
          >
            <img 
              src={item.image} 
              alt={item.caption}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        ))}
      </Carousel>
      <Box 
        sx={{ 
          position: 'absolute', 
          top: '60%',
          left: '10px', 
          transform: 'translateY(-50%)',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 3
        }}
      >
        {carouselItems.map((item, index) => (
          <Box
            key={index}
            onClick={() => setCurrentIndex(index)}
            className = "shadow-md shadow-custom-black"
            sx={{
              marginBottom: '30px',
              border: currentIndex === index ? '2px solid #0759a0' : '2px solid transparent',
              borderRadius: "16px",
              marginLeft:'20px',
              cursor: 'pointer',
              '& img': {
                width: '160px',
                height: '95px',
                objectFit: 'cover',
                transition: 'filter 0.3s ease',
                filter: currentIndex === index ? 'none' : 'grayscale(100%)',
                borderRadius:"16px"
              }
            }}
          >
            <img 
              src={item.image} 
              alt={`Thumbnail ${index}`}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default memo(LoadingCarousel);