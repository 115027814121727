import React from 'react';
import { Box, Button, Paper, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ProductCandidate from "../../images/ProductCandidate.png";

function ProductMenu({ setProductAnchorEl, anchorEl }) {
    
  const handleClose = () => {
    setProductAnchorEl(null);
  };

  const anchorRect = anchorEl ? anchorEl.getBoundingClientRect() : { bottom: 0, left: 0, width: 0 };

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        top: anchorRect.bottom,
        left: `calc(${anchorRect.left}px - 20%)`,
        width: '650px',
        borderRadius: '16px',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
        zIndex: 11,
        mt: 2,
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{ 
          position: 'absolute', 
          top: 8, 
          left: 8, 
          color: 'white' 
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          height: "400px",
        }}
      >
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Button 
            component={Link} 
            to="/product/candidate" 
            variant="outlined" 
            sx={{ mb: 2, fontWeight:"bold", width:"90%", fontSize:"22px" }}
            onClick={handleClose}
          >
            Candidate
          </Button>
          <Button 
            component={Link} 
            to="/product/company" 
            variant="outlined" 
            sx={{ fontWeight:"bold", width:"90%", fontSize:"22px" }}
            onClick={handleClose}
          >
            Company
          </Button>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={ProductCandidate} alt="Product" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
        </Box>
      </Box>
    </Paper>
  );
}

export default ProductMenu;
