import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Navigation from './components/Navigation';
import Home from './components/Home';
import LandingPage from './components/LandingPage';
import ProductCandidate from './components/ProductCandidate';
import ProductCompany from './components/ProductCompany';
import AboutContactUs from './components/AboutContactUs';
import AboutMission from './components/AboutMission';
import AboutTeam from './components/AboutTeam';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{
        backgroundImage: 'linear-gradient(to bottom, #0A0A0A, #222222', // Black gradient
        minHeight: '100vh',
      }}>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/product/candidate" element={<ProductCandidate/>}/>
            <Route path="/product/company" element={<ProductCompany/>}/>
            <Route path="/about/contact" element={<AboutContactUs/>}/>
            <Route path="/about/mission" element={<AboutMission/>}/>
            <Route path="/about/team" element={<AboutTeam/>}/>

          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;