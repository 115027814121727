import React from 'react'
import ProductCarousel from "./products/ProductCarousel"
import { Box } from '@mui/material'

import CQ1 from '../images/CareerQuest1.png';
import CQ2 from '../images/CareerQuest2.png';
import CQ3 from '../images/CareerQuest3.png';
import CQ4 from '../images/CareerQuest4.png';

import ADF1 from '../images/AdventureFSU1.png';
import ADF2 from '../images/AdventureFSU2.png';
import ADF3 from '../images/AdventureFSU3.png';
import ADF4 from '../images/AdventureFSU4.png';

import PN1 from '../images/profNetwork1.png';
import PN2 from '../images/profNetwork2.png';
import PN3 from '../images/profNetwork3.png';
import PN4 from '../images/profNetwork4.png';

import CR1 from '../images/Classroom1.png';
import CR2 from '../images/Classroom2.png';
import CR3 from '../images/Classroom3.png';
import CR4 from '../images/Classroom4.png';


const carouselItems = [
    { image: CQ1, title: 'Career Quests', subtitle:'Define and Achieve Your Career Goals', caption:'AI-Powered Career Guidance', text:'Explore your career goals with our AI-powered Career Quests, designed to guide you every step of the way.', subimages:[CQ1,CQ2,CQ3,CQ4] },
    { image: CR1, title: 'AI Classrooms', subtitle:'	Interactive and Engaging Learning THROUGH OUR Multiplayer AI Classrooms', caption:'Adapted to Your Needs and Goals', text:'Immerse yourself in our multiplayer AI Classrooms for a dynamic learning experience tailored to your individual needs',subimages:[CR1,CR2,CR3,CR4] },
    { image: PN1, title: 'Professional Social Networking', subtitle:'Immersive Networking Experience', caption:'Collaborate and Grow Professionally', text:'Discover our immersive professional networking platform, designed to enhance your career growth. Play, explore, learn, and grow together in the first multiplayer professional social network. Make meaningful connections, explore collaborative opportunities, and stay informed with tailored recommendations. Tailored, evolving guidance to help you define and achieve your professional goals.',subimages:[PN1,PN2,PN3,PN4] },
    { image: ADF1, title: 'FSU ADVENTURE', subtitle:'Dive into the Adventure at FSU', caption:'Meet Charismatic Rebels!',text:"FSU isn't just an education platform—it's a rebellion against EvilCorp's robotic control. Explore our chaotic yet vibrant sanctuary, where ancient knowledge meets futuristic tech. Encounter colorful characters who defy EvilCorp's norms. Together, uncover forbidden knowledge and challenge the status quo.Hack EvilCorp's security using real-world skills. Dive deep into their mainframe to discover their sinister plans and liberate society.",subimages:[ADF1,ADF2,ADF3,ADF4]},
];

export default function ProductCandidate() {
  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          boxShadow: 5,
          overflow: 'hidden',
          width: '95%',
          maxWidth: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ProductCarousel carouselItems={carouselItems}/>
    </Box>
    </Box>
  )
}
