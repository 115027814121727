import { Box, Typography } from '@mui/material';
import React from 'react';
import { CustomButton } from "../assets/customButton";

const Timeline = ({ activeStep, handleStepClick, timelineContent, totalSteps }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        zIndex: 2,
      }}
    >
      {/* Timeline Indicator */}
      <Box
        sx={{
          width: '100%',
          height: '10px',
          backgroundColor: '#432828',
          marginTop: '250px',
          position: 'relative',
        }}
      >
        <Box
              sx={{
                height: '10px',
                width: `${((activeStep - 1) / (totalSteps - 1)) * 100}%`,
                backgroundColor: '#52D5F2',
                transition: 'all 0.5s ease',
                position: 'absolute',
                left: 0,
              }}
            />
        {/* Timeline Circles */}
        {[1, 2, 3, 4].map((step) => (
          <Box
            key={step}
            onClick={() => handleStepClick(step)}
            sx={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: activeStep >= step ? 'white' : 'rgb(85, 69, 69)',
              cursor: 'pointer',
              transition: 'background-color 0.5s ease',
              position: 'absolute',
              top: '-5px', 
              left: `${((step - 1) / (totalSteps - 1)) * 100}%`,
              transform: 'translateX(-50%)',
            }}
          />
        ))}
      </Box>

      {/* Timeline Content */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 6,
          position: 'relative',
        }}
      >
        {timelineContent.map((content, index) => (
          <Box
            key={index}
            sx={{
              width: '22%',
              backgroundColor: '#010F1A',
              borderRadius: '10px',
              padding: '15px',
              boxShadow: 3,
              textAlign: 'center',
              position: 'absolute',
              left: `${(index / (totalSteps - 1)) * 100}%`,
              transform: 'translateX(-50%)',
              top: content.position === 'above' ? '-280px' : '0px', // Adjust vertical positioning
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateX(-50%) scale(1.05)',
                boxShadow: 6,
              },
            }}
          >
            <Typography variant="h6" sx={{ color: 'white' }}>
              {content.title}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CustomButton>
                {content.buttonText}
              </CustomButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Timeline;
