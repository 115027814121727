import React from 'react';
import Footer from './homeComponents/Footer';
import Team from './aboutComponents/Team';

export default function AboutTeam() {
  return (
    <>
    <Team/>
    <Footer/>
    </>
  )
}
