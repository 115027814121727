import React, { memo } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import LoadingCarousel from "./loadingCarousel";
import Logo from "../../images/logo.png";
import Heyteach from "../../images/heyteach.png";
import { CustomButton } from "../assets/customButton";
import {DegreeScale} from "./degreeScale";
import { EnterTextAnimation } from "./enterTextAnimation";

const LoadingOverlay = ({ onEnter, isLoading, progress }) => {
  
  console.log(progress);
  return (
    <Box className="fixed inset-0 flex flex-col justify-center items-center">
      <Box className="absolute top-[5%] left-[1%] right-[1%] bottom-[1%] mt-[2%] rounded-2xl overflow-hidden shadow-black shadow-md">
        
        {/* title */}
        <Typography
          variant="h2"
          sx={{
            position: "absolute",
            top: "2%",
            left: "50%",
            transform: "translateX(-50%)",
            color: "white",
            fontWeight: "bold",
            textShadow: "2px 2px 4px rgba(0,0,0,1)",
            zIndex: 2,
          }}
        >
          Welcome To <br/> 
          Future State University
        </Typography>
        
        {/* carousel */}
        <LoadingCarousel />
        
        {/* loader */}
        <Box
          className="absolute inset-0 flex flex-col justify-center items-center"
          sx={{ zIndex: 2 }}
        >

          <Box className="absolute w-96 h-96 rounded-full border-2 border-sky-blue-dark backdrop-blur-md z-1" />
          
          <DegreeScale/>
          
          <CircularProgress
            size={250}
            thickness={0.75}
            variant="determinate"
            value={progress}
            sx={{
              color: "#075985",
              borderRadius: "50%",
              boxShadow: "inset 0 0 0 0.5px grey",
              "& .MuiCircularProgress-circle": {
                fill: "rgb(0,0,0,0.4)",
              },
            }}
            onClick={!isLoading ? onEnter : undefined} // Handle click event when not loading
          />
          <Box className="absolute inset-0 flex justify-center items-center">
            <Box
              className={`relative w-48 h-48 flex justify-center items-center z-10 ${
                !isLoading ? "cursor-pointer" : "cursor-wait"
              }`}
            >
              <img
                src={Logo}
                alt="Logo"
                className={`absolute w-32 h-32 object-contain transition-all duration-500 ease-in-out ${
                  isLoading ? "opacity-100 scale-100" : "opacity-0 scale-75"
                }`}
              />
              <img
                src={Heyteach}
                alt="Heyteach Logo"
                className={`absolute w-40 h-40 object-contain transition-all duration-500 ease-in-out ${
                  isLoading ? "opacity-0 scale-125" : "opacity-100 scale-100"
                }`}
                onClick={!isLoading ? onEnter : undefined} // Handle click event when not loading
              />
            </Box>
            <EnterTextAnimation isLoading={isLoading}/>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "20px",
              right: "20px",
              zIndex: 2,
            }}
          >
            <CustomButton onClick={onEnter}>Exit To Home</CustomButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(LoadingOverlay);
