import React from 'react';
import DownloadComponent from './homeComponents/DownloadComponent';
import CandidateJourney from './homeComponents/CandidateJourney';
import Features from './homeComponents/Features';
import Footer from './homeComponents/Footer';



function Home() {

  return (
    <>
      <DownloadComponent/>
      <CandidateJourney/>
      <Features/>
      <Footer/>
    </>
  );
}

export default Home;