import { useGLTF } from "@react-three/drei";
import { useEffect, useMemo } from "react";

useGLTF.preload('/export2/scene.gltf');

export const Model = ({ setIsLoading }) => {
  const { scene } = useGLTF('/export2/scene.gltf');
  console.log("Model component rendered");

  useEffect(() => {
    if (scene) {
      console.log("Model loaded");
      setIsLoading(false);  
    }
  }, [scene, setIsLoading]);

  return useMemo(() => <primitive object={scene} />, [scene]);
};
