import React from 'react';
import Footer from './homeComponents/Footer';
import Contact from './aboutComponents/Contact';

export default function AboutContactUs() {
  return (
    <>
      <Contact/>
      <Footer/>
    </>
  )
}
