import { Box, Typography } from "@mui/material";

export const renderCircularText = () => {
    const text = "Click to enter • Click to enter • ";
    const characters = text.split("");
    const degree = 360 / characters.length;

    return characters.map((char, i) => (
      <Typography
        key={i}
        component="span"
        sx={{
          position: 'absolute',
          height: '155px',
          transform: `rotate(${i * degree}deg)`,
          transformOrigin: 'bottom center',
          width: '20px',
          fontSize: '20px',
          fontWeight: 'bold',
          color: 'white',
          left: 'calc(50% - 10px)',
          top: '-80px',
          textAlign: 'center',
        }}
      >
        {char}
      </Typography>
    ));
  };

  export const renderDegreeScale = () => {
    return Array.from({ length: 36 }).map((_, i) => (
      <Box
        key={i}
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          transform: `rotate(${i * 10}deg)`,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '15px',
            left: 'calc(50%)',
            width: '3px',
            height: '10px',
            backgroundColor: 'rgba(255,255,255,0.4)',
          }}
        />
      </Box>
    ));
  };