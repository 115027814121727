import { Box} from '@mui/material';
import React from 'react';
import ThreeScene from './threeJS/threeScene';
import MyUnityGame from './unity/unityGame';

function LandingPage() {
  return (
    <Box sx={{ textAlign: 'center'}}>
        <MyUnityGame />
      </Box>
  );
}

export default LandingPage;