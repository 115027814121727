import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { TikTokIcon } from '../assets/tiktokIcon';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import Logo from "../../images/logo.png"
import Rated from "../../images/rated.png";

export default function Footer() {
  const iconColor = '#52D5F2'; // A blue color, adjust as needed
  const iconSize = 30; // Increased size

  const menuItems = ['Companies', 'Candidate', 'About Us', 'Contact Us', 'Pricing', 'Privacy Policy'];

  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          backgroundColor: "rgba(12, 12, 12, 0.8)", // Added opacity
          boxShadow: 5,
          overflow: 'hidden',
          width: '95%',
          maxWidth: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px',
              gap: '30px', // Increased gap between icons
            }}
          >
            {[FacebookIcon, InstagramIcon, TikTokIcon, LinkedInIcon, YouTubeIcon, TwitterIcon].map((Icon, index) => (
              <IconButton 
                key={index}
                sx={{ 
                  color: iconColor,
                  '& .MuiSvgIcon-root': { fontSize: iconSize },
                  width: "60px",
                  height: "60px", // Ensure consistent height
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon  />
              </IconButton>
            ))}
          </Box>
          <Box
            component="hr"
            sx={{
              width: '90%',
              border: 'none',
              height: '2px',
              backgroundColor: iconColor,
              margin: '10px 0',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <img 
              src={Logo} 
              alt="FSU Logo" 
              style={{
                width: '60px',
                height: 'auto',
                marginBottom: '10px'
              }}
            />
            <Typography 
              variant="h5" 
              sx={{
                color: "white",
                fontWeight: 'medium',
                mb: 2, // Add some margin below the text
              }}
            >
              Future State University
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap', // Allow wrapping on smaller screens
                mt:"10px",
                gap: '60px', // Space between menu items
              }}
            >
              {menuItems.map((item, index) => (
                <Typography
                  key={index}
                  variant="h6"
                  sx={{
                    color: 'white',
                    cursor: 'pointer',
                    '&:hover': {
                      color: iconColor,
                    },
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: '30px', // Push this to the bottom
            mb: 3, // Add some margin at the bottom
          }}
        >
          <img 
            src={Rated} 
            alt="Rated" 
            style={{
              width: '100px', // Adjust this size as needed
              height: 'auto',
              marginBottom: '10px'
            }}
          />
          <Typography 
            variant="body2" 
            sx={{
              color: "white",
              mt: 1,
            }}
          >
            © 2024 FSU. All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}