import { Box, Fade, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { renderCircularText } from "./renderCircularAnimations";

export const EnterTextAnimation=({isLoading})=> {
    const [showDialogue, setShowDialogue] = useState(false);
    useEffect(() => {
        if (!isLoading) {
        const timer = setTimeout(() => setShowDialogue(true), 1000);
        return () => clearTimeout(timer);
        }
    }, [isLoading]);
    return(
        <>
            {isLoading ? (
              <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: "calc(50% + 130px)",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Loading...
              </Typography>
            ) : (
              <Fade in={showDialogue}>
                <Box
                  sx={{
                    position: "absolute",
                    width: "150px",
                    height: "150px",
                    animation: "spin 40s linear infinite",
                    "@keyframes spin": {
                      "0%": {
                        transform: "rotate(0deg)",
                      },
                      "100%": {
                        transform: "rotate(360deg)",
                      },
                    },
                  }}
                >
                  {renderCircularText()}
                </Box>
              </Fade>
            )}
        </>
    );
}